@mixin title-style {
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	font-size: 24px;
	font-weight: 500;
	padding: 15px 10px 13px 10px;
	border-radius: 5px;

	@media (max-width: 1690px) {
		font-size: 20px;
		padding: 10px 10px 8px 10px;
	}
}

@mixin number-style {
	color: #fff;
	text-align: center;
	font-size: 37px;
	line-height: 37px;
	font-weight: bold;
	padding: 10px 0 8px 0;
	margin: 0 5px;
	border: 1px solid #fff;

	border-radius: 5px;

	&.red {
		background: #e0121b;
	}

	&.black {
		background: #181015;
	}

	@media (max-width: 1690px) {
		font-size: 30px;
		line-height: 30px;
	}

	@media (max-width: 1470px) {
		font-size: 25px;
		line-height: 25px;
	}
}

.history {
	padding-top: 60px;

	&--hot-numbers {
		margin-bottom: 25px;
		background-color: var(--secondary-color);
		border-radius: 5px;

		&-title {
			background: var(--secondary-gradient);
			@include title-style;
		}

		&-container {
			padding: 25px 10px 23px 10px;

			&-number {
				@include number-style;
			}

			img {
				width: 100%;
				max-width: 25px;
			}

			@media (max-width: 1690px) {
				padding: 15px 10px 13px 10px;

				img {
					max-width: 21px;
				}
			}

			@media (max-width: 1470px) {
				img {
					max-width: 17px;
				}
			}
		}
	}

	&--cold-numbers {
		margin-bottom: 25px;
		background-color: var(--tertiary-color);
		border-radius: 5px;

		&-title {
			background: var(--tertiary-gradient);
			@include title-style;
		}

		&-container {
			padding: 25px 10px 23px 10px;

			&-number {
				@include number-style;
			}

			img {
				width: 100%;
				max-width: 25px;
			}

			@media (max-width: 1690px) {
				padding: 15px 10px 13px 10px;

				img {
					max-width: 21px;
				}
			}

			@media (max-width: 1470px) {
				img {
					max-width: 17px;
				}
			}
		}
	}

	&--container {
		background-color: var(--quinary-color);
		border-radius: 5px;

		&-title {
			background: var(--quinary-gradient);
			@include title-style;
		}

		&-content {
			padding: 25px 10px 13px 10px;

			&-draw {
				color: #fff;
				text-align: center;
				font-size: 24px;
				line-height: 24px;
				text-transform: uppercase;
				border-radius: 5px;
				font-weight: 500;
				padding-top: 18px;

				@media (max-width: 1470px) {
					font-size: 20px;
					line-height: 20px;
					padding-top: 9px;
				}
			}

			&-value {
				color: #fff;
				text-align: center;
				font-size: 37px;
				line-height: 37px;
				font-weight: bold;
				padding: 10px 0 8px 0;
				margin: 0 auto 15px auto;
				border: 1px solid #fff;

				border-radius: 5px;
				width: 100%;
				max-width: 100px;

				&.red {
					background: #e0121b;
				}

				&.black {
					background: #181015;
				}

				img {
					width: 100%;
					max-width: 25px;
				}

				@media (max-width: 1690px) {
					font-size: 30px;
					line-height: 30px;

					img {
						max-width: 21px;
					}
				}

				@media (max-width: 1470px) {
					font-size: 25px;
					line-height: 25px;
					margin: 0 auto 5px auto;
					padding: 5px 0 3px 0;

					img {
						max-width: 17px;
					}
				}
			}

			@media (max-width: 1690px) {
				padding: 15px 10px 13px 10px;
			}
		}
	}

	@media (max-width: 991px) {
		padding: 30px 10px;
	}
}
